import React, { useState } from "react";
import Modal from "../components/ui/common/Modal";
import PlayerForm from "../components/ui/player/PlayerForm";
import PlayersList from "../components/ui/player/PlayerList";
import SelectForm from "../components/ui/player/SelectForm";
import { usePlayersStore } from "../stores/players-store";

const HomePage = () => {
  const players = usePlayersStore((state) => state.players);
  const getPlayers = usePlayersStore((state) => state.getPlayers);

  const [showModal, setShowModal] = useState();
  const [showModalSearch, setShowModalSearch] = useState();
  const [playerSelected, setPlayerSelected] = useState(null);

  function showModalHandler(player) {
    setShowModal(true);
    setPlayerSelected(player);
  }

  function hideModalHandler() {
    setShowModal(false);
    setPlayerSelected(null);
  }

  function hideModalHandlerSearch() {
    setShowModalSearch(false);
  }

  return (
    <>
      <section className="py-5 bg-gray-100">
        <div className="flex items-start mx-auto max-w-6xl px-6">
          <button
            className="flex items-center space-x-1.5 rounded-lg bg-blue-500 px-2 py-1 text-white duration-100 hover:bg-blue-600 mr-5"
            onClick={() => {
              showModalHandler(null);
            }}
          >
            New Player
          </button>
          <button
            className="flex items-center space-x-1.5 rounded-lg bg-blue-500 px-2 py-1 text-white duration-100 hover:bg-blue-600 mr-5"
            onClick={() => {
              setShowModalSearch(true);
            }}
          >
            Team Selection
          </button>
          <button
            className="flex items-center space-x-1.5 rounded-lg bg-blue-500 px-2 py-1 text-white duration-100 hover:bg-blue-600"
            onClick={() => {
              getPlayers();
            }}
          >
            All Players
          </button>
        </div>
        <PlayersList players={players} onShow={showModalHandler} />
      </section>
      {showModal && (
        <Modal onClose={hideModalHandler}>
          <PlayerForm onClose={hideModalHandler} item={playerSelected} />
        </Modal>
      )}
      {showModalSearch && (
        <Modal onClose={hideModalHandler}>
          <SelectForm onClose={hideModalHandlerSearch}/>
        </Modal>
      )}
    </>
  );
};

export default HomePage;
