import React from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";

// Here is an example of a form with an editable list.
// Next to each input are buttons for insert and remove.
// If the list is empty, there is a button to add an item.
const mama = [
  { name: "defense", value: 99 },
  { name: "attack", value: 25 },
  { name: "speed", value: 0 },
  { name: "strength", value: 0 },
  { name: "stamina", value: 0 },
];

const validationSchema = Yup.object().shape({
  friends: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
        value: Yup.number(),
      })
    )
    .compact((v) => !v.checked)
    .required(),
});

export const FriendList = () => (
  <div>
    <h1>Friend List</h1>
    <Formik
      initialValues={{
        friends: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, errors) =>
        setTimeout(() => {
          console.log(values);          
        }, 500)
      }
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form>
          <FieldArray
            name="friends"
            render={(arrayHelpers) => (
              <div>
                {values.friends.map((friend, index) => (
                  <div key={index} className="flex justify-between mb-2">
                    {/** both these conventions do the same */}

                    <select
                      name={`friends[${index}].name`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white mr-2"
                    >
                      <option value="" label="Select a Position">
                        Select a Position
                      </option>
                      <option value="defense" label="Defense">
                        Defense
                      </option>
                      <option value="attack" label="Attack">
                        Attack
                      </option>
                      <option value="speed" label="Speed">
                        Speed
                      </option>
                      <option value="strength" label="Strength">
                        Strength
                      </option>
                      <option value="stamina" label="Stamina">
                        Stamina
                      </option>
                    </select>
                    <Field
                      name={`friends.${index}.value`}
                      type="number"
                      min="0"
                      max="100"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white mr-2"
                    />

                    <button
                      type="button"
                      onClick={() => arrayHelpers.remove(index)}
                      className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      -
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() =>
                    arrayHelpers.push({ name: "defense", value: "0" })
                  }
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-5"
                >
                  +
                </button>
              </div>
            )}
          />
          <button
            type="submit"
            className="w-1/2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Save
          </button>
        </Form>
      )}
    />
  </div>
);
