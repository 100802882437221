import React, { useState } from "react";
import { toast } from "react-toastify";
import { usePlayersStore } from "../../../stores/players-store";

const PlayerItem = ({ player, onShow }) => {
  const deletePlayer = usePlayersStore((state) => state.deletePlayer);

  async function deleteHandler(id) {
    const proceed = window.confirm("Are you sure?");
    if (proceed) {
      try {
        await deletePlayer(id);
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        return;
      }
    }
  }

  return (
    <>
      <article className="rounded-xl bg-white p-3 shadow-black hover:shadow-xl hover:transform hover:scale-105 duration-300 ">
        <div className="relative flex items-end overflow-hidden rounded-xl">
          <div className="w-full">
            <img
              src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
              alt="player jpg"
              className="object-cover h-48 w-96"
            />
          </div>
        </div>

        <div className="mt-1 p-2">
          <div className="mt-3 flex items-end justify-between mb-2">
            <h2 className="text-slate-700 uppercase">{player.name}</h2>
          </div>
          <p className="mt-1 text-sm text-center mb-4">
            <span className="px-2 py-1 bg-green-400 text-white text-sm font-medium rounded-full uppercase">
              {player.position}
            </span>
          </p>
          <p className="mx-auto grid max-w-6xl  grid-cols-2 gap-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
            {player.playerSkills.map((skill) => (
              <span
                className="px-2 py-1 bg-orange-400 text-white text-xs text-center rounded-full mr-2"
                key={skill.id}
              >
                <b>
                  {skill.skill}: <i>{skill.value}</i>
                </b>
              </span>
            ))}
          </p>

          <div className="mt-3 flex items-end justify-between">
            <button
              className="flex items-center space-x-1.5 rounded-lg bg-blue-500 px-2 py-1 text-white duration-100 hover:bg-blue-600"
              onClick={() => {
                onShow(player);
              }}
            >
              Edit
            </button>
            <button
              className="flex items-center space-x-1.5 rounded-lg bg-blue-500 px-2 py-1 text-white duration-100 hover:bg-blue-600"
              onClick={() => {
                deleteHandler(player.id);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </article>
    </>
  );
};

export default PlayerItem;
