import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainNavigation from "./MainNavigation";

const AdminLayout = () => {
  return (
    <>
      <MainNavigation />
      <main className="mt-24">
        <Outlet />
      </main>
      <ToastContainer />
    </>
  );
};

export default AdminLayout;
