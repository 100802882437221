import React from "react";
import PlayerItem from "./PlayerItem";

const PlayersList = ({ players, onShow }) => {
  return (
    <>      
      <div className="mx-auto grid max-w-6xl grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {players.map((player) => (
          <PlayerItem key={player.id} player={player} onShow={onShow}  />
        ))}
      </div>
    </>
  );
};

export default PlayersList;
