import { create } from "zustand";
import axios from "../api/axios";

export const usePlayersStore = create((set, get) => ({
  players: [],
  getPlayers: async () => {
    const response = await axios.get("player");

    set((state) => ({
      players: response.data,
    }));
  },
  addPlayer: async (player) => {
    try {
      await axios.post(`player`, {
        name: player.name,
        position: player.position,
        playerSkills: player.playerSkills,
      });

      usePlayersStore.getState().getPlayers();
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  editPlayer: async (playerId, player) => {
    try {
      await axios.put(`player/${playerId}`, {
        name: player.name,
        position: player.position,
        playerSkills: player.playerSkills,
      });
      usePlayersStore.getState().getPlayers();
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  deletePlayer: async (playerId) => {
    const config = {
      headers: {
        Authorization:
          "Bearer SkFabTZibXE1aE14ckpQUUxHc2dnQ2RzdlFRTTM2NFE2cGI4d3RQNjZmdEFITmdBQkE=",
      },
    };

    try {
      await axios.delete(`player/${playerId}`, config);
      usePlayersStore.getState().getPlayers();
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  },
  searchTeam: async (search) => {
    const response = await axios.post("team/process", search.playersSearchItems);

    set((state) => ({
      players: response.data,
    }));
  },
}));

// start fetching initially
usePlayersStore.getState().getPlayers();
