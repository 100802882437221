import { Field, FieldArray, Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { usePlayersStore } from "../../../stores/players-store";

const SelectSchema = Yup.object().shape({
  position: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  mainSkill: Yup.string()
    .min(2, "Too Short!")
    .max(25, "Too Long!")
    .required("Required")
    .oneOf(["defender", "midfielder", "forward"]),
  skills: Yup.array(),
});

const SelectForm = (props) => {
  const searchTeam = usePlayersStore((state) => state.searchTeam);

  return (
    <Formik
      initialValues={{
        playersSearchItems: [],
      }}
      //   validationSchema={props.item ? PlayerSchema : PlayerSchema}
      onSubmit={async (values, { resetForm }) => {        
        try {
          await searchTeam(values);
          props.onClose();
        } catch (error) {
          toast.error(error.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "light",
          });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-hide="authentication-modal"
            onClick={props.onClose}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
              Select Team
            </h3>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <h3 className="mb-3">Search Players:</h3>
                <FieldArray
                  name="playersSearchItems"
                  render={(arrayHelpers) => (
                    <div>
                      {values.playersSearchItems.map((friend, index) => (
                        <div key={index} className="flex justify-between mb-2">
                          {/** both these conventions do the same */}

                          <select
                            name={`playersSearchItems[${index}].position`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={friend.position}
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white mr-2"
                          >
                            <option value="" label="Select a Position">
                              Select a Position
                            </option>
                            <option value="defender" label="Defender">
                              Defender
                            </option>
                            <option value="midfielder" label="Midfielder">
                              Midfielder
                            </option>
                            <option value="forward" label="Forward">
                              Forward
                            </option>
                          </select>
                          <select
                            name={`playersSearchItems[${index}].mainSkill`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={friend.mainSkill}
                            required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white mr-2"
                          >
                            <option value="" label="Select a Main skill">
                              Select Main skill
                            </option>
                            <option value="defense" label="Defense">
                              Defense
                            </option>
                            <option value="attack" label="Attack">
                              Attack
                            </option>
                            <option value="speed" label="Speed">
                              Speed
                            </option>
                            <option value="strength" label="Strength">
                              Strength
                            </option>
                            <option value="stamina" label="Stamina">
                              Stamina
                            </option>
                          </select>
                          <Field
                            name={`playersSearchItems.${index}.numberOfPlayers`}
                            type="number"
                            min="0"
                            max="100"
                            required
                            title="Number of players"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/3 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white mr-2"
                          />

                          <button
                            type="button"
                            onClick={() => arrayHelpers.remove(index)}
                            className=" text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          >
                            -
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() =>
                          arrayHelpers.push({
                            position: "",
                            numberOfPlayers: 1,
                            mainSkill: "",
                          })
                        }
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mb-5"
                      >
                        +
                      </button>
                    </div>
                  )}
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="submit"
                  className="w-1/2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Save
                </button>
                <button
                  className="text-sm text-blue-700 hover:underline dark:text-blue-500 mt-1"
                  onClick={props.onClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default SelectForm;
