import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AdminLayout from "./components/layout/MainLayout";
import { FriendList } from "./pages/FriendList";
import HomePage from "./pages/HomePage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AdminLayout />,
    //errorElement: <ErrorPage />,
    children: [{ index: true, element: <HomePage /> }],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

// function App() {
//   return <FriendList/>;
// }

export default App;
